import * as THREE from "three";
import { Pane } from "tweakpane";
import "./script.js";
// import * as pHidden from './resources/script.js';

// Helper Funcs
function remap(number, inMin, inMax, outMin, outMax) {
  return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

const randomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

const randomFloat = (min, max) => {
  return Math.random() * (max - min) + min;
};

const PIXEL_RATIO = Math.min(window.devicePixelRatio, 2);

const SIZES = {
  width: window.innerWidth,
  height: window.innerHeight,
};

const mapVapeAmount = () => {
  return remap(PARAMS.vaporAmount, 0, 1, 0.1, PARAMS.vaporType >= 2 ? 100 : 25);
};

const mapVapeSpeed = () => {
  return remap(PARAMS.vaporSpeed, 0, 1, 0, 20);
};

const mapVapeIntensity = () => {
  return remap(PARAMS.vaporIntensity, 0, 1, 0.5, 1);
};

const mapVapeScale = () => {
  return remap(
    PARAMS.vaporScale,
    0,
    1,
    0.002,
    PARAMS.vaporType >= 2 ? 0.5 : 0.2
  );
};

const mapVapeOpacity = () => {
  return PARAMS.currentKabaOpacity;
};

const mapGlitchAngle = () => {
  return remap(PARAMS.glitchAngle, 0, 1, -Math.PI, Math.PI);
};

// Objects
const PARAMS = {
  starfieldColor: "#999999",
  bgColor: "#1C1C1C",
  kabaOpacity: 0.8,
  currentKabaOpacity: 0.8,
  kabaColor1: "#894f2d",
  kabaColor2: "#513e3a",
  followMouse: true,
  mouseSpeed: 0.2,
  kabaZoom: 0.42,
  vaporType: 0,
  vaporVignette: false,
  vaporIntensity: 0.6,
  vaporAmount: 0.2,
  vaporSpeed: 0.004,
  vaporScale: 0.12,
  grungify: false,
  grungifyIntensity: 0.9,
  grungifyScale: 0.5,
  grungifySpeed: 0.2,
  grungifyLineIntensity: 0.01,
  grungifyLines: 100,
  glitch: false,
  gltichType: 1,
  glitchSep: 0.0005, //Math.random() / 30
  glitchAngle: 3.1, //randomFloat( - Math.PI, Math.PI )
  glitchSeed: 0.35, //Math.random()
  glitchSeedX: 0.2, //randomFloat( - 1, 1 )
  glitchSeedY: 0.2, //randomFloat( - 1, 1 )
  glitchDistortX: 0.5, //randomFloat( 0, 1 )
  glitchDistortY: 0.5, //randomFloat( 0, 1 )
  glitchColumns: 0.01,
};

const MOUSE_POS = {
  currentX: 0,
  currentY: 0,
  targetX: 0,
  targetY: 0,
};

const tpContainer = document.querySelector(".pane");
const pane = new Pane({
  title: "Kaba Pattern Tweaks",
  container: tpContainer,
});
const btnHide = pane.addButton({ title: "exit tweak mode" });
btnHide.on("click", (ev) => {
  document.querySelector(".pane").style.display = pHidden ? "block" : "none";
  document.querySelector("nav").style.display = !pHidden ? "flex" : "none";
  document.querySelector("#welcome").style.display = !pHidden
    ? "block"
    : "none";
  document.querySelector("#who-we-are").style.display = !pHidden
    ? "flex"
    : "none";
  document.querySelector("#contact").style.display = !pHidden ? "grid" : "none";
  document.querySelector("#request").style.display = !pHidden ? "grid" : "none";
  document.querySelector("footer").style.display = !pHidden ? "flex" : "none";
  pHidden = !pHidden;
});
var folder = pane.addFolder({
  title: "Basics",
});
folder
  .addInput(PARAMS, "starfieldColor", { label: "starfield" })
  .on("change", () => {
    for (let i = 0; i < NUM_STARS; i++) {
      particlesMaterials[i] = new THREE.PointsMaterial({
        color: PARAMS.starfieldColor,
        sizeAttenuation: true,
        map: stars[i],
        alphaTest: 0.5,
        transparent: true,
        size: 0.035,
      });
      particles[i].material = particlesMaterials[i];
    }
  });
folder.addInput(PARAMS, "bgColor", { label: "background" }).on("change", () => {
  renderer.setClearColor(new THREE.Color(PARAMS.bgColor));
});
folder
  .addInput(PARAMS, "kabaZoom", {
    label: "zoom",
    min: 0.01,
    max: 1,
    step: 0.01,
  })
  .on("change", () => {
    renderImageTexture();
  });
folder
  .addInput(PARAMS, "kabaOpacity", {
    label: "opacity",
    min: 0,
    max: 1,
    step: 0.01,
  })
  .on("change", () => {
    PARAMS.currentKabaOpacity = PARAMS.kabaOpacity;
    textureMaterial.uniforms.uOpacity.value = mapVapeOpacity();
    fxMaterial.uniforms.uOpacity.value = mapVapeOpacity();
  });
folder.addInput(PARAMS, "kabaColor1", { label: "top" }).on("change", () => {
  textureMaterial.uniforms.uColor1.value = new THREE.Color(PARAMS.kabaColor1);
});
folder.addInput(PARAMS, "kabaColor2", { label: "bottom" }).on("change", () => {
  textureMaterial.uniforms.uColor2.value = new THREE.Color(PARAMS.kabaColor2);
});
folder.addInput(PARAMS, "followMouse", { label: "mouse" });
folder.addInput(PARAMS, "mouseSpeed", {
  label: "amount",
  min: 0.01,
  max: 1,
  step: 0.01,
});
folder = pane.addFolder({
  title: "Vapor",
});
const vBtn = folder.addButton({
  title: "randomize!",
  label: "vapor",
});
vBtn.on("click", () => {
  PARAMS.vaporType = randomInt(0, 6);
  fxMaterial.uniforms.uNType.value = PARAMS.vaporType;
  PARAMS.vaporIntensity = Math.random();
  fxMaterial.uniforms.uNoiseIntensity.value = mapVapeIntensity();
  PARAMS.vaporVignette = Math.random() < 0.5;
  fxMaterial.uniforms.uVignette.value = PARAMS.vaporVignette ? 1 : 0;
  PARAMS.vaporAmount = Math.random();
  fxMaterial.uniforms.uNoise.value = mapVapeAmount();
  PARAMS.vaporScale = Math.random();
  fxMaterial.uniforms.uNoiseScale.value = mapVapeScale();
  PARAMS.vaporSpeed = Math.random() / 25;
  fxMaterial.uniforms.uNoiseSpeed.value = mapVapeSpeed();
  pane.refresh();
});
folder
  .addInput(PARAMS, "vaporType", {
    label: "type",
    options: {
      simplex: 0,
      voronoi: 1,
      vornü_1: 2,
      vornü_2: 3,
      vornü_3: 4,
    },
  })
  .on("change", () => {
    fxMaterial.uniforms.uNType.value = PARAMS.vaporType;
    fxMaterial.uniforms.uNoise.value = mapVapeAmount();
    fxMaterial.uniforms.uNoiseScale.value = mapVapeScale();
  });
folder
  .addInput(PARAMS, "vaporVignette", { label: "vignette" })
  .on("change", () => {
    fxMaterial.uniforms.uVignette.value = PARAMS.vaporVignette ? 1 : 0;
  });
folder
  .addInput(PARAMS, "vaporAmount", {
    label: "amount",
    step: 0.001,
    min: 0,
    max: 1,
  })
  .on("change", () => {
    fxMaterial.uniforms.uNoise.value = mapVapeAmount();
  });
folder
  .addInput(PARAMS, "vaporIntensity", {
    label: "intensity",
    step: 0.001,
    min: 0,
    max: 1,
  })
  .on("change", () => {
    fxMaterial.uniforms.uNoiseIntensity.value = mapVapeIntensity();
  });
folder
  .addInput(PARAMS, "vaporScale", {
    label: "scale",
    step: 0.001,
    min: 0,
    max: 1,
  })
  .on("change", () => {
    fxMaterial.uniforms.uNoiseScale.value = mapVapeScale();
  });
folder
  .addInput(PARAMS, "vaporSpeed", {
    label: "speed",
    step: 0.001,
    min: 0,
    max: 1,
  })
  .on("change", () => {
    fxMaterial.uniforms.uNoiseSpeed.value = mapVapeSpeed();
  });
folder = pane.addFolder({
  title: "Glitch",
});
folder.addInput(PARAMS, "glitch", { label: "glitch" }).on("change", () => {
  fxMaterial.uniforms.uGlitch.value = PARAMS.glitch ? 1.0 : 0.0;
});
const dbtn = folder.addButton({
  title: "randomize!",
  label: "glitch",
});
dbtn.on("click", () => {
  PARAMS.gltichType = randomInt(0, 3);
  fxMaterial.uniforms.uGType.value = PARAMS.gltichType;
  PARAMS.glitchSep = Math.random();
  fxMaterial.uniforms.uGAmount.value = PARAMS.glitchSep / 50;
  PARAMS.glitchAngle = Math.random();
  fxMaterial.uniforms.uGAngle.value = remap(
    PARAMS.glitchAngle,
    0,
    1,
    -Math.PI,
    Math.PI
  );
  PARAMS.glitchSeed = Math.random();
  fxMaterial.uniforms.uGSeed.value = PARAMS.glitchSeed;
  PARAMS.glitchSeedX = remap(Math.random(), 0, 1, -1, 1);
  fxMaterial.uniforms.uGSeedX.value = PARAMS.glitchSeedX;
  PARAMS.glitchSeedY = remap(Math.random(), 0, 1, -1, 1);
  fxMaterial.uniforms.uGSeedY.value = PARAMS.glitchSeedY;
  PARAMS.glitchDistortX = Math.random();
  fxMaterial.uniforms.uGlitchionX.value = PARAMS.glitchDistortX;
  PARAMS.glitchDistortY = Math.random();
  fxMaterial.uniforms.uGlitchionY.value = PARAMS.glitchDistortY;
  fxMaterial.uniforms.uGColumns.value = PARAMS.glitchColumns;
  PARAMS.glitchColumns = Math.random();
  pane.refresh();
});
folder
  .addInput(PARAMS, "gltichType", {
    label: "type",
    options: {
      first: 0,
      second: 1,
      third: 2,
    },
  })
  .on("change", () => {
    fxMaterial.uniforms.uGType.value = PARAMS.gltichType;
  });
folder
  .addInput(PARAMS, "glitchSep", { label: "sep", step: 0.001, min: 0, max: 1 })
  .on("change", () => {
    fxMaterial.uniforms.uGAmount.value = PARAMS.glitchSep / 50;
  });
folder
  .addInput(PARAMS, "glitchAngle", {
    label: "angle",
    step: 0.001,
    min: 0,
    max: 1,
  })
  .on("change", () => {
    fxMaterial.uniforms.uGAngle.value = remap(
      PARAMS.glitchAngle,
      0,
      1,
      -Math.PI,
      Math.PI
    );
  });
folder
  .addInput(PARAMS, "glitchSeed", {
    label: "seed root",
    step: 0.001,
    min: 0,
    max: 1,
  })
  .on("change", () => {
    fxMaterial.uniforms.uGSeed.value = PARAMS.glitchSeed;
  });
folder
  .addInput(PARAMS, "glitchSeedX", {
    label: "seed x",
    step: 0.01,
    min: -1,
    max: 1,
  })
  .on("change", () => {
    fxMaterial.uniforms.uGSeedX.value = PARAMS.glitchSeedX;
  });
folder
  .addInput(PARAMS, "glitchSeedY", {
    label: "seed y",
    step: 0.01,
    min: -1,
    max: 1,
  })
  .on("change", () => {
    fxMaterial.uniforms.uGSeedY.value = PARAMS.glitchSeedY;
  });
folder
  .addInput(PARAMS, "glitchDistortX", {
    label: "x distort",
    step: 0.01,
    min: 0,
    max: 1,
  })
  .on("change", () => {
    fxMaterial.uniforms.uGlitchionX.value = PARAMS.glitchDistortX;
  });
folder
  .addInput(PARAMS, "glitchDistortY", {
    label: "y distort",
    step: 0.01,
    min: 0,
    max: 1,
  })
  .on("change", () => {
    fxMaterial.uniforms.uGlitchionY.value = PARAMS.glitchDistortY;
  });
folder
  .addInput(PARAMS, "glitchColumns", {
    label: "columns",
    step: 0.001,
    min: 0,
    max: 1,
  })
  .on("change", () => {
    fxMaterial.uniforms.uGColumns.value = PARAMS.glitchColumns;
  });
folder = pane.addFolder({
  title: "Grunge",
});
folder.addInput(PARAMS, "grungify", { label: "grungify" }).on("change", () => {
  textureMaterial.uniforms.uNoise.value = PARAMS.grungify ? 1.0 : 0.0;
});
folder
  .addInput(PARAMS, "grungifyIntensity", {
    label: "intensity",
    step: 0.01,
    min: 0,
    max: 1,
  })
  .on("change", () => {
    textureMaterial.uniforms.uNoiseIntensity.value = PARAMS.grungifyIntensity;
  });
folder
  .addInput(PARAMS, "grungifyScale", {
    label: "scale",
    step: 0.01,
    min: 0,
    max: 1,
  })
  .on("change", () => {
    textureMaterial.uniforms.uNoiseScale.value = remap(
      PARAMS.grungifyScale,
      0,
      1,
      0.01,
      20
    );
  });
folder
  .addInput(PARAMS, "grungifySpeed", {
    label: "speed",
    step: 0.01,
    min: 0,
    max: 1,
  })
  .on("change", () => {
    textureMaterial.uniforms.uNoiseSpeed.value = remap(
      PARAMS.grungifySpeed,
      0,
      1,
      0,
      10
    );
  });
folder
  .addInput(PARAMS, "grungifyLines", {
    label: "lines",
    step: 1,
    min: 0,
    max: 4096,
  })
  .on("change", () => {
    textureMaterial.uniforms.uLines.value = PARAMS.grungifyLines;
  });
folder
  .addInput(PARAMS, "grungifyLineIntensity", {
    label: "linetensity",
    step: 0.01,
    min: 0,
    max: 1,
  })
  .on("change", () => {
    textureMaterial.uniforms.uLineIntensity.value =
      PARAMS.grungifyLineIntensity;
  });

/**
 * Base
 */
THREE.ColorManagement.enabled = false;
const canvas = document.querySelector(".webgl");
const scene = new THREE.Scene();
const directionalLight = new THREE.DirectionalLight("#ffffff", 1);

directionalLight.position.set(1, 1, 0);
scene.add(directionalLight);

/**
 * Kaba Vapor
 */
// Image Texture
const textureCanvas = document.createElement("canvas");
textureCanvas.width = SIZES.width;
textureCanvas.height = SIZES.height;
const ctx = textureCanvas.getContext("2d");
var textureImg = new Image();
textureImg.onload = function () {
  scene.add(textureMesh);
  renderImageTexture();
};
const loadImage = () => {
  textureImg.src = new URL("./hippo.png", import.meta.url);
};

// Shader Image
const texVertexShader = /*glsl*/ `
    varying vec2 vUv;

    void main () {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        vUv = uv;
    }
`;

const texFragmentShader = /*glsl*/ `
    uniform sampler2D sampler;
    uniform float uTime;
    uniform float uNoise;
    uniform float uNoiseIntensity;
    uniform float uNoiseSpeed;
    uniform float uNoiseScale;
    uniform float uLineIntensity;
    uniform float uLines;
    uniform vec3 uColor1;
    uniform vec3 uColor2;
    uniform float uOpacity;
    varying vec2 vUv;

    //	Simplex 3D Noise 
    //	by Ian McEwan, Ashima Arts
    //
    vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
    vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}

    float snoise(vec3 v){ 
        const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
        const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);

        // First corner
        vec3 i  = floor(v + dot(v, C.yyy) );
        vec3 x0 =   v - i + dot(i, C.xxx) ;

        // Other corners
        vec3 g = step(x0.yzx, x0.xyz);
        vec3 l = 1.0 - g;
        vec3 i1 = min( g.xyz, l.zxy );
        vec3 i2 = max( g.xyz, l.zxy );

        //  x0 = x0 - 0. + 0.0 * C 
        vec3 x1 = x0 - i1 + 1.0 * C.xxx;
        vec3 x2 = x0 - i2 + 2.0 * C.xxx;
        vec3 x3 = x0 - 1. + 3.0 * C.xxx;

        // Permutations
        i = mod(i, 289.0 ); 
        vec4 p = permute( permute( permute( 
                    i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
                + i.y + vec4(0.0, i1.y, i2.y, 1.0 )) 
                + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));

        // Gradients
        // ( N*N points uniformly over a square, mapped onto an octahedron.)
        float n_ = 1.0/7.0; // N=7
        vec3  ns = n_ * D.wyz - D.xzx;

        vec4 j = p - 49.0 * floor(p * ns.z *ns.z);  //  mod(p,N*N)

        vec4 x_ = floor(j * ns.z);
        vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)

        vec4 x = x_ *ns.x + ns.yyyy;
        vec4 y = y_ *ns.x + ns.yyyy;
        vec4 h = 1.0 - abs(x) - abs(y);

        vec4 b0 = vec4( x.xy, y.xy );
        vec4 b1 = vec4( x.zw, y.zw );

        vec4 s0 = floor(b0)*2.0 + 1.0;
        vec4 s1 = floor(b1)*2.0 + 1.0;
        vec4 sh = -step(h, vec4(0.0));

        vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
        vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;

        vec3 p0 = vec3(a0.xy,h.x);
        vec3 p1 = vec3(a0.zw,h.y);
        vec3 p2 = vec3(a1.xy,h.z);
        vec3 p3 = vec3(a1.zw,h.w);

        //Normalise gradients
        vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
        p0 *= norm.x;
        p1 *= norm.y;
        p2 *= norm.z;
        p3 *= norm.w;

        // Mix final noise value
        vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
        m = m * m;
        return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1), 
                                        dot(p2,x2), dot(p3,x3) ) );
    }

    vec3 adjustSaturation(vec3 color, float value) {
        // https://www.w3.org/TR/WCAG21/#dfn-relative-luminance
        const vec3 luminosityFactor = vec3(0.2126, 0.7152, 0.0722);
        vec3 grayscale = vec3(dot(color, luminosityFactor));

        return mix(grayscale, color, 1.0 + value);
    }

    void main () {
        vec4 texColor = texture2D(sampler, vUv);
        if (texColor.a < 0.9) {
            discard;
        } else {
            vec3 color = vec3(mix(uColor1,uColor2,1.-vUv.y));
            if(uNoise == 1.){
                // make some noise
                vec2 uv = gl_FragCoord.xy;
                uv /= uNoiseScale;
                float n = snoise(vec3(uv, uTime * uNoiseSpeed));
                // add noise
                vec3 colorNoise = vec3(n * 0.5 + 0.5);
                color *= mix(colorNoise * uNoiseIntensity * 2., color, 1. - uNoiseIntensity);
                // get us a sine and cosine
                vec2 sc = vec2( sin( vUv.y * uLines ), cos( vUv.y * uLines ) );
                // add scanlines
                color += color * vec3( sc.x, sc.y, sc.x ) * uLineIntensity;
            }
            color = adjustSaturation(color, uOpacity*0.5);
            gl_FragColor =  vec4(color, uOpacity);
        }
    }
`;

const textureGeometry = new THREE.PlaneGeometry(SIZES.width, SIZES.height);
const textureMaterial = new THREE.ShaderMaterial({
  uniforms: {
    sampler: { value: null },
    uTime: { value: 0 },
    uNoise: { value: PARAMS.grungify ? 1.0 : 0.0 },
    uNoiseIntensity: { value: PARAMS.grungifyIntensity },
    uNoiseScale: { value: remap(PARAMS.grungifyScale, 0, 1, 0.01, 20) },
    uNoiseSpeed: { value: remap(PARAMS.grungifySpeed, 0, 1, 0, 10) },
    uLineIntensity: { value: PARAMS.grungifyLineIntensity },
    uLines: { value: PARAMS.grungifyLines },
    uColor1: { value: new THREE.Color(PARAMS.kabaColor1) },
    uColor2: { value: new THREE.Color(PARAMS.kabaColor2) },
    uOpacity: { value: mapVapeOpacity() },
  },
  vertexShader: texVertexShader,
  fragmentShader: texFragmentShader,
  transparent: true,
});
const textureMesh = new THREE.Mesh(textureGeometry, textureMaterial);
textureMesh.position.z = -1300;

// Shader Vapor
const generateHeightmap = () => {
  const dt_size = 64;
  const data_arr = new Float32Array(dt_size * dt_size);
  const length = dt_size * dt_size;
  for (let i = 0; i < length; i++) {
    const val = randomFloat(0, 1);
    data_arr[i] = val;
  }
  const texture = new THREE.DataTexture(
    data_arr,
    dt_size,
    dt_size,
    THREE.RedFormat,
    THREE.FloatType
  );
  texture.needsUpdate = true;
  return texture;
};

const fxVertexShader = /*glsl*/ `
    varying vec2 vUv;

    void main () {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        vUv = uv;
    }
`;

const fxFragmentShader = /*glsl*/ `
    uniform sampler2D sampler;
    uniform int uGlitch;
    uniform int uNType;
    uniform int uGType;
    uniform sampler2D uGDisplace;
    uniform float uGAmount;
    uniform float uGAngle;
    uniform float uGSeed;
    uniform float uGSeedX;
    uniform float uGSeedY;
    uniform float uGlitchionX;
    uniform float uGlitchionY;
    uniform float uGColumns;
    uniform float uTime;
    uniform vec2 uMouse;
    uniform float uNoiseIntensity;
    uniform int uVignette;
    uniform float uNoise;
    uniform float uNoiseSpeed;
    uniform float uNoiseScale;
    uniform float uOpacity;
    varying vec2 vUv;

    #define M_PI 3.14159265358979323846

    float rand(vec2 n) { 
        return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
    }

    const mat2 myt = mat2(.12121212, .13131313, -.13131313, .12121212);
    const vec2 mys = vec2(1e4, 1e6);

    vec2 rhash(vec2 uv) {
    uv *= myt;
    uv *= mys;
    return fract(fract(uv / mys) * uv);
    }

    vec3 hash(vec3 p) {
    return fract(
        sin(vec3(dot(p, vec3(1.0, 57.0, 113.0)), dot(p, vec3(57.0, 113.0, 1.0)),
                dot(p, vec3(113.0, 1.0, 57.0)))) *
        43758.5453);
    }

    vec3 voronoi3d(const in vec3 x, const in int m) {
    vec3 p = floor(x);
    vec3 f = fract(x);

    float id = 0.0;
    vec2 res = vec2(100.0);
    for (int k = -1; k <= 1; k++) {
        for (int j = -1; j <= 1; j++) {
        for (int i = -1; i <= 1; i++) {
            vec3 b = vec3(float(i), float(j), float(k));
            vec3 r = vec3(b) - f + hash(p + b);
            float d = dot(r, r);

            float cond = max(sign(res.x - d), 0.0);
            float nCond = 1.0 - cond;

            float cond2 = nCond * max(sign(res.y - d), 0.0);
            float nCond2 = 1.0 - cond2;

            id = (dot(p + b, vec3(1.0, 57.0, 113.0)) * cond) + (id * nCond);
            res = vec2(d, res.x) * cond + res * nCond;

            if(m == 1){
                res.y = cond2 * d + nCond2 * res.y;
            }
            else if(m == 2){
                res.y = -cond2 * d + nCond2 * res.y;
            } 
            else if(m == 3){
                res.y = cond2 * d + nCond*-nCond2*-nCond2 * res.y;
            }
            else {
                res.y = cond * d - -nCond2 * res.x;
            }
        }
        }
    }

    return vec3(sqrt(res), abs(id));
    }

    //	Simplex 3D Noise 
    //	by Ian McEwan, Ashima Arts
    //
    vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
    vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}

    float snoise(vec3 v){ 
    const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
    const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);

    // First corner
    vec3 i  = floor(v + dot(v, C.yyy) );
    vec3 x0 =   v - i + dot(i, C.xxx) ;

    // Other corners
    vec3 g = step(x0.yzx, x0.xyz);
    vec3 l = 1.0 - g;
    vec3 i1 = min( g.xyz, l.zxy );
    vec3 i2 = max( g.xyz, l.zxy );

    //  x0 = x0 - 0. + 0.0 * C 
    vec3 x1 = x0 - i1 + 1.0 * C.xxx;
    vec3 x2 = x0 - i2 + 2.0 * C.xxx;
    vec3 x3 = x0 - 1. + 3.0 * C.xxx;

    // Permutations
    i = mod(i, 289.0 ); 
    vec4 p = permute( permute( permute( 
                i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
            + i.y + vec4(0.0, i1.y, i2.y, 1.0 )) 
            + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));

    // Gradients
    // ( N*N points uniformly over a square, mapped onto an octahedron.)
    float n_ = 1.0/7.0; // N=7
    vec3  ns = n_ * D.wyz - D.xzx;

    vec4 j = p - 49.0 * floor(p * ns.z *ns.z);  //  mod(p,N*N)

    vec4 x_ = floor(j * ns.z);
    vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)

    vec4 x = x_ *ns.x + ns.yyyy;
    vec4 y = y_ *ns.x + ns.yyyy;
    vec4 h = 1.0 - abs(x) - abs(y);

    vec4 b0 = vec4( x.xy, y.xy );
    vec4 b1 = vec4( x.zw, y.zw );

    vec4 s0 = floor(b0)*2.0 + 1.0;
    vec4 s1 = floor(b1)*2.0 + 1.0;
    vec4 sh = -step(h, vec4(0.0));

    vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
    vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;

    vec3 p0 = vec3(a0.xy,h.x);
    vec3 p1 = vec3(a0.zw,h.y);
    vec3 p2 = vec3(a1.xy,h.z);
    vec3 p3 = vec3(a1.zw,h.w);

    //Normalise gradients
    vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
    p0 *= norm.x;
    p1 *= norm.y;
    p2 *= norm.z;
    p3 *= norm.w;

    // Mix final noise value
    vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
    m = m * m;
    return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1), 
                                    dot(p2,x2), dot(p3,x3) ) );
    }

    void main () {
        vec4 t0 = vec4(0);
        float v = (uVignette == 0 ? 0. : distance(vUv,vec2(0.5)));
        if(uNType<1){
            float a = snoise(vec3(vUv * uNoise, uTime * uNoiseSpeed * 0.75)) * uNoiseScale;
            float b = snoise(vec3(vUv * uNoise, uTime * uNoiseSpeed * 0.75)) * uNoiseScale;
            // t0 = texture2D(sampler, vUv - vec2(a, b) + uMouse * 0.005);
            t0 = texture2D(sampler, vUv - vec2(a, b) + uMouse * 0.002);
        }
        else {
            vec3 a = voronoi3d(vec3(vUv * -uNoise, uTime * uNoiseSpeed), uNType) * uNoiseScale;
            vec3 b = -voronoi3d(vec3(vUv * uNoise, -uTime * uNoiseSpeed), uNType) * uNoiseScale;
            t0 = texture2D(sampler, vUv + a.xy + b.xy + uMouse * 0.005);
        }

        if(uGlitch>0) {
            vec2 p = vUv;
            float xs = floor(gl_FragCoord.x / 0.1);
            float ys = floor(gl_FragCoord.y / 0.1);
            //based on staffantans glitch shader for unity https://github.com/staffantan/unityglitch
            float disp = texture2D(uGDisplace, p*uGSeed*uGSeed).r;
            if(p.y<uGlitchionX+uGColumns && p.y>uGlitchionY-uGColumns*uGSeed) {
                if(uGSeedX>0.){
                    p.y = 1. - (p.y + uGlitchionY);
                }
                else {
                    p.y = uGlitchionY;
                }
            }
            if(p.x<uGlitchionY+uGColumns && p.x>uGlitchionY-uGColumns*uGSeed) {
                if(uGSeedY>0.){
                    p.x=uGlitchionX;
                }
                else {
                    p.x = 1. - (p.x + uGlitchionX);
                }
            }
            p.x+=disp*uGSeedX*(uGSeed/0.5);
            p.y+=disp*uGSeedY*(uGSeed/0.5);
            
            //base from RGB shift shader
            vec2 offset = uGAmount * vec2( cos(uGAngle), sin(uGAngle));
            vec4 cr = texture2D(sampler, p + offset);
            vec4 cga = texture2D(sampler, p);
            vec4 cb = texture2D(sampler, p - offset);
            vec4 finalColor = vec4(cr.r, cga.g, cb.b, cga.a);
            
            //add noise
            // vec4 snow = 200.*uGAmount*vec4(rand(vec2(xs * uGSeed,ys * uGSeed*50.))*0.2);
            // finalColor += snow;

            //this is starting to get somewhere
            if(uGType==0){
                gl_FragColor = vec4(t0.xyz - finalColor.xyz, uNoiseIntensity-v);
            }
            else if(uGType==1) {
                gl_FragColor = vec4(finalColor.xyz - t0.xyz, uNoiseIntensity-v);
            }
            else {
                gl_FragColor = vec4((t0.xyz - finalColor.xyz) / length(t0.xyz), uNoiseIntensity-v);
            }
            //this produces kinda alpha
            // gl_FragColor = vec4(t0.xyz * uColorPersist, uAlphaPersist * t0.a);
        }
        else {
            // gl_FragColor = vec4(t0.xyz, pow(uNoiseIntensity * 0.999, uTime));
            gl_FragColor = vec4(t0.xyz, (uNoiseIntensity-v)*uOpacity);
        }
    }
`;

const fxScene = new THREE.Scene();
const fxGeometry = new THREE.PlaneGeometry(SIZES.width, SIZES.height);
const fxMaterial = new THREE.ShaderMaterial({
  vertexShader: fxVertexShader,
  fragmentShader: fxFragmentShader,
  transparent: true,
  uniforms: {
    sampler: { value: null },
    uGlitch: { value: PARAMS.glitch ? 1.0 : 0.0 },
    uNType: { value: PARAMS.vaporType },
    uGType: { value: PARAMS.gltichType },
    uGDisplace: { value: generateHeightmap() },
    uGAmount: { value: PARAMS.glitchSep / 50 },
    uGAngle: { value: mapGlitchAngle() },
    uGSeed: { value: PARAMS.glitchSeed },
    uGSeedX: { value: PARAMS.glitchSeedX },
    uGSeedY: { value: PARAMS.glitchSeedY },
    uGlitchionX: { value: PARAMS.glitchDistortX },
    uGlitchionY: { value: PARAMS.glitchDistortY },
    uGColumns: { value: PARAMS.glitchColumns },
    uTime: { value: 0 },
    uMouse: { value: new THREE.Vector2(-1, -1) },
    uNoiseIntensity: { value: mapVapeIntensity() },
    uVignette: { value: PARAMS.vaporVignette ? 1 : 0 },
    uNoise: { value: mapVapeAmount() },
    uNoiseSpeed: { value: mapVapeSpeed() },
    uNoiseScale: { value: mapVapeScale() },
    uOpacity: { value: mapVapeOpacity() },
  },
});
const fxMesh = new THREE.Mesh(fxGeometry, fxMaterial);
fxScene.add(fxMesh);

/**
 * Starfield
 */
var stars = [];
const loader = new THREE.TextureLoader();
const loadStars = () => {
  loader.load(new URL("./star1.png", import.meta.url), function (texture) {
    stars.push(texture);
    loader.load(new URL("./star2.png", import.meta.url), function (texture) {
      stars.push(texture);
      loader.load(new URL("./star3.png", import.meta.url), function (texture) {
        stars.push(texture);
        loadParticles();
      });
    });
  });
};

// Geometry
const NUM_STARS = 3;
const SCREEN_HEIGHT = 4;
const P_SPREAD = 5;
const particlesCount = 2500;
const particlesMaterials = [];
const particles = [];

const loadParticles = () => {
  for (let j = 0; j < NUM_STARS; j++) {
    const positions = new Float32Array(particlesCount * 3);
    for (let i = 0; i < particlesCount; i++) {
      positions[i * 3 + 0] = (Math.random() - 0.5) * P_SPREAD * SCREEN_HEIGHT;
      positions[i * 3 + 1] = -remap(
        Math.random(),
        0,
        1,
        -P_SPREAD * 0.25,
        P_SPREAD * SCREEN_HEIGHT
      );
      positions[i * 3 + 2] = Math.random() * P_SPREAD;
    }

    const particlesGeometry = new THREE.BufferGeometry();
    particlesGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(positions, 3)
    );

    // Material
    particlesMaterials[j] = new THREE.PointsMaterial({
      color: PARAMS.starfieldColor,
      sizeAttenuation: true,
      map: stars[j],
      alphaTest: 0.5,
      transparent: true,
      size: 0.035,
    });

    // Points
    particles.push(new THREE.Points(particlesGeometry, particlesMaterials[j]));
    scene.add(particles[j]);
  }
};

window.addEventListener("resize", () => {
  handleMobileCheck();

  SIZES.width = window.innerWidth;
  SIZES.height = window.innerHeight;
  camera.aspect = SIZES.width / SIZES.height;
  camera.updateProjectionMatrix();
  renderer.setSize(SIZES.width, SIZES.height);
  renderBufferA.setSize(SIZES.width, SIZES.height);
  renderBufferB.setSize(SIZES.width, SIZES.height);
  renderImageTexture();

  fitObjects();
});

const fitObjects = () => {
  var vFOV = THREE.MathUtils.degToRad(camera.fov); // convert vertical fov to radians
  var vH = 2 * Math.tan(vFOV / 2) * 6; // visible height
  var vW = vH * camera.aspect; // visible width
  // group.scale.y = vH + 8;
  // group.scale.x = vW + 2;
  // group.position.x = -(vW + 2) * 0.39;
  // group.position.y = vH * 0.5;
};

/**
 * Scroll
 */
let scrollY = 0;
let progress = 0;
let currentSection = 0;
const FX_CUTOFF = 0.5;

window.addEventListener("load", () => {
  handleMobileCheck();
  camera.position.y =
    -((scrollY / SIZES.height) * SCREEN_HEIGHT) + SCREEN_HEIGHT;
});

document.body.addEventListener("scroll", (e) => {
  handleScroll(e);
});

const handleScroll = (e) => {
  scrollY = Math.min(Math.max(0, e.target.scrollTop), e.target.scrollHeight);
  progress = (scrollY / window.innerHeight) % 1;
  const newSection = Math.floor(scrollY / SIZES.height);
  if (newSection != currentSection) {
    currentSection = newSection;
  }
  if (currentSection == 0) {
    if (previousTime > 0.3 && !foundOut) {
      findOut();
    }
    gsap.to(PARAMS, {
      currentKabaOpacity:
        PARAMS.kabaOpacity * (1 - remap(progress, 0, FX_CUTOFF, 0, 1)),
      duration: 0.1,
      onUpdate: () => {
        if (currentSection > 0) {
          PARAMS.currentKabaOpacity = 0;
        }
        textureMaterial.uniforms.uOpacity.value = mapVapeOpacity();
        fxMaterial.uniforms.uOpacity.value = mapVapeOpacity();
      },
    });
  }
  // Animate camera
  gsap.to(camera.position, {
    y: -((scrollY / SIZES.height) * SCREEN_HEIGHT) + SCREEN_HEIGHT,
    duration: 0.35,
    ease: "power2.inout",
  });
};

var isMobile = false;
const handleMobileCheck = () => {
  isMobile = window.innerWidth <= 900; //kludge to account for mobile browsers that hide/show their chrome on scroll
};

/**
 * Cursor
 */
const cursor = {};
cursor.x = 0;
cursor.y = 0;

window.addEventListener("pointermove", (e) => {
  if (!PARAMS.followMouse) {
    return;
  }
  cursor.x = e.clientX / SIZES.width - 0.5;
  cursor.y = e.clientY / SIZES.height - 0.5;
  const x = (e.pageX / SIZES.width) * 2 - 1;
  const y = (1 - e.pageY / SIZES.height) * 2 - 1;
  MOUSE_POS.targetX = x;
  MOUSE_POS.targetY = y;

  // HIPPO
  gsap.to(textureMesh.rotation, {
    y: -Math.PI * 0.25 * PARAMS.mouseSpeed * x,
    ease: "power2.inout",
    duration: 0.75,
  });
  gsap.to(textureMesh.rotation, {
    x: Math.PI * 0.25 * PARAMS.mouseSpeed * y,
    ease: "power2.inout",
    duration: 0.75,
  });
});

var foundOut = false;
document.querySelector("#btn-find-out").addEventListener("click", () => {
  findOut();
});

const findOut = () => {
  foundOut = true;
  const DUR = 0.65;
  const z = remap(Math.random(), 0, 1, -500, -200);
  const y = remap(Math.random(), 0, 1, -200, 200);
  gsap.to(textureMesh.position, { z: z, duration: DUR, ease: "power1.out" });
  gsap.to(textureMesh.position, { y: y, duration: DUR, ease: "power1.out" });
  // vaporIntensity: 0.4,
  // vaporAmount: 0.2,
  // vaporSpeed: 0.004,
  // vaporScale: 0.1,
  gsap.to(PARAMS, {
    vaporIntensity: 0.6,
    duration: DUR,
    onUpdate: () => {
      fxMaterial.uniforms.uNoiseIntensity.value = mapVapeIntensity();
    },
  });
  gsap.to(PARAMS, {
    vaporAmount: 0.075,
    duration: DUR,
    onUpdate: () => {
      fxMaterial.uniforms.uNoise.value = mapVapeAmount();
    },
  });
  gsap.to(PARAMS, {
    vaporSpeed: 0.002,
    duration: DUR,
    onUpdate: () => {
      fxMaterial.uniforms.uNoiseSpeed.value = mapVapeSpeed();
    },
  });
  gsap.to(PARAMS, {
    vaporScale: 0.9,
    duration: DUR,
    onUpdate: () => {
      fxMaterial.uniforms.uNoiseScale.value = mapVapeScale();
    },
  });
  PARAMS.kabaOpacity = FX_CUTOFF;
  gsap.to(PARAMS, {
    currentKabaOpacity: PARAMS.kabaOpacity,
    duration: DUR,
    onUpdate: () => {
      if (currentSection > 0) {
        PARAMS.currentKabaOpacity = 0;
      }
      textureMaterial.uniforms.uOpacity.value = mapVapeOpacity();
      fxMaterial.uniforms.uOpacity.value = mapVapeOpacity();
    },
  });
};

/**
 * Camera
 */
// Group
const cameraGroup = new THREE.Group();
scene.add(cameraGroup);

// Base camera
const fxCam = new THREE.OrthographicCamera(
  -SIZES.width * 0.5,
  SIZES.width * 0.5,
  SIZES.height * 0.5,
  -SIZES.height * 0.5,
  0.1,
  P_SPREAD * 2
);
fxCam.position.z = P_SPREAD;
fxCam.aspect = SIZES.width / SIZES.height;

const camera = new THREE.PerspectiveCamera(
  35,
  SIZES.width / SIZES.height,
  0.1,
  5000
);
camera.position.z = 6;
camera.useQuaternion = true;

camera.aspect = SIZES.width / SIZES.height;
cameraGroup.add(camera);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
  alpha: true,
  premultipliedAlpha: false,
});
// renderer.outputColorSpace = THREE.sRGBEncoding;
renderer.setSize(SIZES.width, SIZES.height);
renderer.setPixelRatio(PIXEL_RATIO);
// renderer.setClearColor(new THREE.Color(PARAMS.bgColor));
// renderer.setClearAlpha(0);

let renderBufferA = new THREE.WebGLRenderTarget(
  SIZES.width * PIXEL_RATIO,
  SIZES.height * PIXEL_RATIO,
  { format: THREE.RGBAFormat }
);
let renderBufferB = new THREE.WebGLRenderTarget(
  SIZES.width * PIXEL_RATIO,
  SIZES.height * PIXEL_RATIO,
  { format: THREE.RGBAFormat }
);

/**
 * Animate
 */
const clock = new THREE.Clock();
var previousTime = 0;

const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  const deltaTime = elapsedTime - previousTime;
  previousTime = elapsedTime;

  const parallaxX = cursor.x * 0.05;
  const parallaxY = -cursor.y * 0.05;
  cameraGroup.position.x +=
    (parallaxX - cameraGroup.position.x) * 5 * deltaTime;
  cameraGroup.position.y +=
    (parallaxY - cameraGroup.position.y) * 5 * deltaTime;

  if (PARAMS.followMouse) {
    const mappedSpeed = remap(PARAMS.mouseSpeed, 0, 1, 0, 50);
    const mouseSpeed = deltaTime * mappedSpeed;
    MOUSE_POS.currentX += (MOUSE_POS.targetX - MOUSE_POS.currentX) * mouseSpeed;
    MOUSE_POS.currentY += (MOUSE_POS.targetY - MOUSE_POS.currentY) * mouseSpeed;

    fxMaterial.uniforms.uMouse.value.x = MOUSE_POS.currentX;
    fxMaterial.uniforms.uMouse.value.y = MOUSE_POS.currentY;
  }

  fxMaterial.uniforms.sampler.value = renderBufferB.texture;
  fxMaterial.uniforms.uTime.value = elapsedTime;

  if (currentSection == 0 && progress < FX_CUTOFF) {
    renderer.autoClearColor = false;
    renderer.setRenderTarget(renderBufferA);
    renderer.clearColor();
    renderer.render(fxScene, fxCam);
    renderer.render(scene, camera);
    renderer.setRenderTarget(null);
    renderer.render(fxScene, fxCam);
  } else {
    renderer.render(scene, camera);
  }

  const temp = renderBufferA;
  renderBufferA = renderBufferB;
  renderBufferB = temp;

  if (textureMaterial) {
    textureMaterial.uniforms.uTime.value = elapsedTime;
  }

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

loadImage();
loadStars();
tick();
fitObjects();

const renderImageTexture = () => {
  var w, h, a;
  const z = remap(PARAMS.kabaZoom, 0, 1, 0, window.innerWidth <= 900 ? 1.2 : 2);
  if (textureImg.height > textureImg.width) {
    a = textureImg.width / textureImg.height;
    h = SIZES.height * z;
    w = h * a;
  } else {
    a = textureImg.height / textureImg.width;
    w = SIZES.width * z;
    h = w * a;
  }
  ctx.clearRect(0, 0, SIZES.width, SIZES.height);
  ctx.drawImage(
    textureImg,
    (SIZES.width - w) * 0.5,
    (SIZES.height - h) * 0.5,
    w,
    h
  );
  textureMaterial.uniforms.sampler.value = new THREE.CanvasTexture(
    textureCanvas
  );
};
